.schedulingPages {
  position: fixed;
  top: 0; left: 0;
  width: 100vw; height: var(100vh, 100vh);
  z-index: 1001;
  background: rgba(110,110,110,.7);
  display: flex;
  justify-content: center;
  align-items: center;
  
  &__container {
    margin: 0 1rem;
    max-width: 1200px;
    min-width: 15rem;
    background: #FFF;
    border-radius: 5px;
    text-align: center;
    border: 1px solid $lightPurple;
  
    &__header {
      height: 70px;
      padding: 0 2rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-size: 1.3rem;
      border-bottom: 1px solid #CECECE;

      button {
        position: relative;
        background: transparent;
        border: 0;
        cursor: pointer;
      }
    }
  
    .filler {
      flex: 1;
    }
  
    &__grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 20px;
      overflow-y: auto;
  
      .schedulingPage {
        width: 340px;
        padding: 20px;
        margin: 10px;
        background-color: #F2F2F2;
        display: inline-flex;
        flex-direction: column;
        text-align: left;
  
        .name {
          font-size: 1.2em;
          font-weight: 500;
          margin-bottom: 10px;
        }
  
        button {
          background: transparent;
          border: 0;
          cursor: pointer;
          width: fit-content;
  
          display: flex;
          justify-content: flex-start;
          text-decoration: none;
          padding: 4px 6px;
          font-size: 10pt;
  
          &:hover {
            background-color: #E2E2E2;
          }
  
          svg {
            margin-right: 3px;
            opacity: .7;
          }
        }
      }
    }
  }
}
