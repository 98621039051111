.payouts {
  position: relative;
  body.admin-mode & {
    padding-top: 71px;
  }
  & > header {
    padding: 3rem 3rem 2.5rem;

    h2 {
      margin: 0;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 40px;
      line-height: normal;
      font-weight: 600;
      color: #0D1C8B;
    }
  }
  & > section {
    .adminBody {
      padding: 5rem 6.25rem 0;
      display: flex;
    }

    .header {
      padding: 0 3rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: flex-end;
      gap: 40px;

      &__button {
        border-bottom: 3px solid transparent;
        padding-bottom: 20px;
        width: fit-content;
        cursor: pointer;

        font-family: 'Source Sans Pro', sans-serif;
        font-weight: 600;
        font-size: 14px;
        line-height: normal;
        color: #333333;
      }

      &__buttonSelected {
        border-bottom: 3px solid #0D1C8B;
        color: #0D1C8B;
      }
    }

    .body {
      padding: 25px 3rem 3rem;

      &__biggerPadding {
        padding: 35px 3rem 3rem;
      }

      &__text {
        color: #000;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.32px;
        margin-bottom: 37px;
      }

      &__tableHeader {
        display: grid;
        grid-template-columns: 0.5fr 1fr 0.8fr 2fr;
        border-bottom: 1px solid #D0D0D3;
        padding: 0 0 12px;

        &__headerItem {
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 14px;
          font-weight: 600;
          line-height: normal;
          color: #828282;
        }
      }

      &__tableBody {
        display: grid;
        grid-template-columns: 0.5fr 1fr 0.8fr 2fr;
        border-bottom: 1px solid #D0D0D3;
        padding: 0 0 12px;

        &__bodyItem {
          padding-top: 12px;
          color: #000;
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0.32px;

          display: flex;
          flex-direction: column;
          gap: 6px;

          span {
            font-weight: 700;
          }

          &__showMore {
            color: #0D1C8B;
            font-family: 'Source Sans Pro', sans-serif;
            font-size: 14px;
            font-weight: 600;
            text-decoration-line: underline;
            cursor: pointer;
            user-select: none;
          }

          &__invoiceLine {
            cursor: pointer;

            &:hover {
              text-decoration-line: underline;
            }
          }
        }
      }

      &__noInvoices {
        padding-top: 12px;
        color: #000;
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 0.32px;
      }
    }
  }
}