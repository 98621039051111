.claims {
  position: relative;
  body.admin-mode & {
    padding-top: 71px;
  }
  & > header {
    padding: 3rem 3rem 2.5rem;

    h2 {
      margin: 0;
      font-family: 'Source Sans Pro', sans-serif;
      font-size: 40px;
      line-height: normal;
      font-weight: 600;
      color: #0D1C8B;
    }
  }
  & > section {
    .adminBody {
      padding: 5rem 6.25rem 0;
      display: flex;

      &__greyBoxContainer {
        width: 100%;
        background: rgba(217, 217, 217, 0.2);
        border-radius: 12px;
        padding: 64px 0 20px;
        box-sizing: border-box;

        display: flex;
        flex-direction: column;
        align-items: center;

        &__greyBoxText {
          font-family: 'Avenir Next';
          font-weight: 500;
          font-size: 16px;
          line-height: 16px;
          text-align: center;
          color: #000000;
          margin: 24px 0 27px;
        }

        &__downloadButton {
          padding: 0;
          margin: 0;
          border: none;
          outline: none;
          background: transparent;
          cursor: pointer;
          display: flex;
          align-items: center;
          gap: 13px;

          font-family: 'Avenir';
          font-weight: 500;
          font-size: 14px;
          line-height: 19px;
          color: #0D1C8B;
        }
      }
    }

    .header {
      padding: 0 3rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      display: flex;
      align-items: flex-end;
      gap: 40px;

      &__button {
        border-bottom: 3px solid transparent;
        padding-bottom: 20px;
        width: fit-content;
        cursor: pointer;

        font-family: 'Source Sans Pro', sans-serif;
        font-weight: 600;
        font-size: 14px;
        line-height: normal;
        color: #333333;
      }

      &__buttonSelected {
        border-bottom: 3px solid #0D1C8B;
        color: #0D1C8B;
      }
    }

    .body {
      padding: 3rem;

      &__noClaimsBox {
        background: transparent;
        padding: 22px 0;
        box-sizing: border-box;

        display: flex;
        flex-direction: column;
        align-items: center;

        &__title {
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 20px;
          font-weight: 600;
          line-height: normal;
          letter-spacing: 0.4px;
          text-align: center;
          color: #000000;
          margin: 24px 0 12px;
        }

        &__text {
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.32px;
          text-align: center;
          color: #000000;
        }
      }

      &__suggestText {
        font-family: 'Source Sans Pro', sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.32px;
        color: #000000;
        margin-bottom: 32px;

        span {
          font-weight: 600;
        }
      }

      &__tableHeader {
        display: flex;
        flex: 1;
        align-items: center;
        border-bottom: 1px solid #D0D0D3;
        padding: 0 0 12px;

        &__headerItem {
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 14px;
          font-weight: 600;
          line-height: normal;
          color: #828282;
          display: flex;
          flex: 0.6;
        }

        &__headerBigger {
          flex: 2;
        }
      }

      &__tableBody {
        display: flex;
        flex: 1;
        align-items: center;
        padding: 12px 0;
        border-bottom: 1px solid #D0D0D3;
        min-height: 67px;

        &__bodyItem {
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: normal;
          color: #000;
          display: flex;
          flex: 0.6;
        }

        &__bodyItemBigger {
          font-family: 'Source Sans Pro', sans-serif;
          font-size: 16px;
          font-weight: 400;
          line-height: normal;
          color: #000;
          display: flex;
          flex: 2;

          display: flex;
          align-items: center;
          justify-content: space-between;

          &__expirationDay {
            color: #F04444;
          }

          &__buttonWrapper {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            gap: 24px;
          }

          &__submitButton {
            border: 1px solid #BDBDBD;
            background: transparent;
            border-radius: 40px;
            padding: 12px;
            box-sizing: border-box;
            outline: none;
            cursor: pointer;

            font-family: 'Source Sans Pro', sans-serif;
            font-size: 14px;
            font-weight: 600;
            line-height: normal;
            color: #0D1C8B;
          }

          &__deleteButton {
            height: 20px;
            width: 20px;
            cursor: pointer;
          }
        }
      }
    }
  }
}