$closedWidth: 86px;
$partialWidth: 290px;

.godMode {
  position: fixed;
  top: 0; left: 0;
  width: 100%;
  z-index: 101;

  @include flex;
  border-bottom: 1px solid $lightGrey;
  padding: 1rem 1rem 1rem 316px;
  background: #FFF;

  display: none;

  &__dropDown {
    min-width: 300px;
  }
}

.calendar__container {
  .mbsc-ios.mbsc-calendar-wrapper {
    position: fixed;
    top: 104px; left: 0;
    padding-left: $partialWidth;
    width: 100%;
    z-index: 100;
    background: #FFF;
  }
  .mbsc-ios.mbsc-schedule-wrapper {
    padding-top: 216px;
  }
  .mbsc-ios.mbsc-schedule-header {
    position: fixed;
    top: 164px;
    left: 0;
    padding-left: $partialWidth;
    width: 100%;
    z-index: 100;
    background: #FFF !important;
    border-bottom: 0 !important;
  }
  .mbsc-schedule-all-day-wrapper {
    border-top: 1px solid #CCC;
  }
}

body.admin-mode {
  .godMode {
    display: flex;

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }
  .calendar__container {
    .mbsc-ios.mbsc-calendar-wrapper {
      top: 159px;
    }
    .mbsc-ios.mbsc-schedule-wrapper {
      padding-top: 271px;
    }
    .mbsc-ios.mbsc-schedule-header {
      top: 219px;
    }
  }
}

body.appointmentView {
  .godMode {
    padding-left: 96px;
  }
  .calendar__container {
    .mbsc-ios.mbsc-calendar-wrapper {
      padding-left: $closedWidth;
    }
    .mbsc-ios.mbsc-schedule-header {
      padding-left: $closedWidth;
    }
  }
}

.app {
  height: var(--vh, 100vh);

  @media screen and (max-width: 1024px) {
    height: calc(100vh - 87px);
  }

  aside {
    position: fixed;
    top: 0; left: 0;
    z-index: 150;
    @include flex;
    flex-direction: column;
    justify-content: flex-start;
    width: $partialWidth; height: var(--vh, 100vh);
    transition: $base-transition;
    box-shadow: 20px 0px 40px rgba(0, 0, 0, 0.05);

    @media screen and (max-width: 1024px) {
      display: none;
    }

    h2 {
      img {
        display: none;
        max-width: 100%;
        height: auto;
      }
      svg {

      }
    }

    nav {
      position: relative;
      padding: 2rem;
      width: $partialWidth; height: var(--vh, 100vh);

      a {
        text-decoration: none;
      }

      button {
        margin-bottom: 1rem;
        background: transparent;
        border: 0;
        width: 100%;
        cursor: pointer;
        padding: .5rem;
        font-family: $base-font-family;

        display: flex;
        justify-content: flex-start;
        align-items: center;

        i {
          display: inline-block;
          margin-right: 1rem;;
          width: 15px; height: 15px;
        }

        &.active {
          border: 1px solid $basePurple;
          border-radius: 10px;
        }

        &[data-messages] {
          position: relative;
          &::before {
            content: attr(data-messages);
            @include unicorn;
            background-color: $baseRed;
            position: absolute;
            right: 4px; top: 4px;
            z-index: 100;
            width: 20px; height: 20px;
            color: #FFF;
            border: 1px solid #FFF;
            border-radius: 50%;
          }
        }
      }
    }
  }

  &.rightDrawerIsOpen {
    h2 {
      padding: 0 1rem;
      img {
        display: block;
      }
      svg {
        display: none;
      }
    }
  }

  &__mobileHeader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
    background: #FFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
    padding: 0 24px;

    &__headerRight {
      width: 20px;
    }

    @media screen and (min-width: 1024px) {
      display: none;
    }
  }

  &__sideOverlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #333333;
    opacity: 0;
    z-index: 101;
    visibility: hidden;
    transition: opacity 1s;

    &--open {
      opacity: 0.3;
      visibility: visible;
    }

    @media screen and (min-width: 1024px) {
      display: none;
    }
  }

  &__mobileSide {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 102;
    transition: transform 0.3s ease-in-out;
    background: #FFF;
    width: 270px;
    transform: translateX(-100%);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    &--open {
      transform: translateX(0);
    }

    &__sideHeader {
      padding: 57px 24px 15px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    &__sideBody {
      height: 100%;
      padding: 33px 24px;
      display: flex;
      flex-direction: column;
      gap: 30px;

      a {
        text-decoration: none;
      }
    }

    &__sideFooter {
      padding: 18px 24px 40px;
      border-top: 1px solid rgba(0, 0, 0, 0.1);

      &__sideButton {
        display: flex;
        align-items: center;
        padding: 0;
        gap: 17px;
        border: none;
        background: transparent;

        div {
          display: flex;
          align-items: center;
        }

        i {
          padding: 0;
          margin: 0;
          width: 20px;
          height: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        span {
          font-family: 'Avenir';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          letter-spacing: 0.02em;
          color: #000000;
        }
      }
    }

    @media screen and (min-width: 1024px) {
      display: none;
    }
  }

  &__main {
    margin-left: $partialWidth;
    width: calc(100% - #{$partialWidth});
    height: var(--vh, 100vh);
    background: #FFF;
    transition: $base-transition;

    @media screen and (max-width: 1024px) {
      margin-left: 0;
      width: 100%;
      margin-top: 87px;
      height: 100%;
    }
  }

  &.rightDrawerIsOpen {
    aside {
      width: $closedWidth;
      nav {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        padding: 0;
        width: $closedWidth;
        button {
          @include unicorn;
          width: 35px; height: 35px;
          font-family: $base-font-family;
          i {
            margin: 0;
          }
          span {
            display: none;
          }
        }
      }
    }
    .app__main {
      margin-left: $closedWidth;
      width: calc(100% - #{$closedWidth});
    }
  }

  &.hide-sidebar {
    .app__main {
      margin-left: 0;
      width: 100%;
    }
  }
}

.eligibilityTable {
  body.admin-mode & {
    padding-top: 71px;
  }
}


.appointmentsTable {
  display: none;
  @include bp(BigMama) {
    display: block;
  }
  
  body.admin-mode & {
    padding-top: 71px;
  }

  &__search {
    &__container {
      padding: 1rem;
    }
    input {
      width: 100%;
      border: 1px solid #D0D0D3;
      font-size: 1rem;
      padding: .75rem;
      border-radius: 5px;
      background: #FFF;
    }
  }

  &__filters {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &__container {
      padding: 0 1rem;
      margin-bottom: 1rem;
    }

    .tags {
      margin-left: .25rem;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      & > .tag {
        cursor: pointer;
        border-radius: 4px;
        &:hover {
          border-color: $baseHoverBlue;
          background: $baseHoverBlue;
        }
      }
    }
  }
}

.betaFlag {
  background: #F3F4F9;
  padding: 4px;
  box-sizing: border-box;
  margin-left: 8px;
  border-radius: 4px;

  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.02em;
  color: #000000;
}

.referProvider {
  background: #F3F4F9;
  padding: 4px;
  box-sizing: border-box;
  margin-left: 8px;
  border-radius: 4px;

  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.02em;
  color: #000000;
}