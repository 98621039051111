.settings {
  position: relative;
  body.admin-mode & {
    padding-top: 71px;
  }
  & > header {
    padding: 3rem;

    h2 {
      font-family: 'Source Sans Pro', sans-serif;
      margin: 0;
      font-size: 40px;
      line-height: 2.625rem;
      font-weight: 500;
      color: $basePurple;
    }
  }
  & > section {
    .body {
      padding: 1.5rem 3rem;
      display: flex;

      .info {
        width: 100%;

        .locationInput {
          width: 33.813rem;
          height: 2.5rem;
          border: 1px solid rgba(0, 0, 0, 0.2);
          border-radius: 8px;

          font-family: 'Source Sans Pro', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          letter-spacing: 0.02em;

          ::placeholder {
            color: #828282;
          }
        }

        .bigLabel {
          font-family: 'Source Sans Pro', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          color: #000000;
          margin-bottom: 0.75rem;
        }

        .infoBox {
          margin-top: 16px;
          max-width: 80%;
          display: flex;
          width: fit-content;
          justify-content: center;
          align-items: center;
          flex-direction: row;
          gap: 12px;
          padding: 15px 20px;
          background-color: #F2F2F2;
          margin-bottom: 32px;

          svg {
            width: 20px;
            height: 20px;
          }

          p {
            width: 100%;
            padding: 0;
            margin: 0;
            font-family: 'Source Sans Pro', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            color: #000;
          }
        }

        .divider {
          height: 1px;
          width: 100%;
          background: rgba(0, 0, 0, 0.1);
          margin-bottom: 2rem;
        }

        .wrapper {
          margin-bottom: 2rem;
          display: flex;
          width: 65%;
          align-items: flex-start;
          justify-content: space-between;
        }

        .marginTop {
          margin-top: 0.75rem;
        }

        .marginTopMedium {
          margin-top: 20px !important;
        }

        .marginTopBig {
          margin-top: 48px;
        }

        .wrapperColumn {
          display: flex;
          flex-direction: column;

          p {
            margin: 0px;
          }
        }

        .wrapperRow {
          display: flex;
          flex-direction: row;
          align-items: center;
        }

        .label {
          font-family: 'Source Sans Pro', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 19px;
          color: #151625;
          margin-bottom: 0.5rem;
        }

        .regularText {
          font-family: 'Source Sans Pro', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 19px;
          color: #000;
          margin-bottom: 0;
        }

        .buttonsWrapper {
          display: flex;
          align-items: center;
          gap: 11px;
          margin-top: 44px;

          button {
            height: 48px;
            font-family: 'Source Sans Pro', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }

        .greyBox {
          background: #F2F2F2;
          border-radius: 4px;
          margin-top: 1.063rem;
          margin-bottom: 2.25rem;
          padding: 1rem;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          gap: 1.375rem;

          .normalText {
            font-family: 'Source Sans Pro', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #000000;
            margin: 0;
          }

          .greyText {
            font-family: 'Source Sans Pro', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #4F4F4F;
            margin: 0;

            span {
              color: #0D1C8B;
              text-decoration: underline;
            }
          }
        }

        .noBorderBottom {
          border-bottom: none;
          margin-bottom: 0;
        }

        .availButtons {
          display: flex;
          align-items: center;
          gap: 11px;
          margin-top: 44px;

          button {
            height: 48px;
            font-family: 'Source Sans Pro', sans-serif;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }

        .copyWrapper {
          display: flex;
          margin-top: 0.563rem;
          gap: 0.75rem;
        }
      }
    }

    .connectButton {
      @include unicorn;
      padding: 3px 12px 3px 12px;
      border: 1px solid $baseBlue;
      border-radius: 6px;
      background: #fff;
      color: $baseBlue;
      font-size: 16px;
      font-weight: 600;
      cursor: pointer;
      height: 48px;

      img {
        width: 34px;
        height: 34px;
        margin-right: 6px;
      }
    }
  }

  .urlTooltip {
    box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19) !important;
    background-color: #fff;
    opacity: 1 !important;
  }
}