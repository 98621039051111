.eligibility-practice-group,
.eligibility-practice-group-isadmin {
  position: relative;

  & > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem 3rem 2.5rem;
    box-sizing: border-box;

    h2 {
      margin: 0;
      font-family: "Source Sans Pro", sans-serif;
      font-size: 40px;
      line-height: normal;
      font-weight: 600;
      color: #0d1c8b;
    }

    .upload-button {
      align-items: center;
      justify-content: flex-end;
    }
  }

  & > section {
    padding: 40px 48px;
  }
}

.eligibility-practice-group-isadmin {
  margin-top: 4rem;
}
