
.mbsc-timegrid-item.mbsc-selected.mbsc-ios {
  background: $basePurple !important;
}

.zayaCareIcon__calendar {
  position: absolute;
  top: 50%; left: 12px;
  transform: translateY(-50%);
}

.form-row {
  margin-bottom: 2rem;
  &.split-row {
    @include flex;
    align-items: flex-start;
    & > div {
      width: 49%;
    }
  }

  @media screen and (max-width: 1024px) {
    &.split-row {
      flex-direction: column;

      & > div {
        width: 100%;
      }
    }
  }
}

.zayaInput {
  position: relative;
  // border: 1px solid #A0A3D0;
  // background: #FFF;
  // border-radius: 20px;
  // transition: $base-transition;
  // height: 53px;

  &__wrapper {
    border: 1px solid rgba(0, 0, 0, 0.2);
    background: #FFF;
    border-radius: 8px;
    transition: $base-transition;
    height: 53px;
    overflow: hidden;
    overflow-x: auto;
    display: flex;
    align-items: center;
  }

  .timeInput {
    font-size: 1rem;
    letter-spacing: 0.02em;
    color: #000000;
    p {
      padding-left: 15px;
    }
    .timeLengthReadOnly {
      padding-left: 1rem;
      border-left: 1px solid #D0D0D3;
      position: absolute;
      top: 1.1rem;
      right: 1rem;
    }
    &__control {
      position: relative;
      input {
        width: 100%;
        height: 51px;
        border: 0;
        background: transparent;
        padding: 0 1rem;
        font-family: 'Avenir Next';
        font-size: 1rem;
        letter-spacing: 0.02em;
        color: #000000;
        white-space: nowrap;
        outline: none;
      }
      span {
        position: absolute;
        top: 50%; right: 1rem;
        transform: translateY(-50%);
        padding-left: 1rem;
        border-left: 1px solid #D0D0D3;
      }
    }

    &__edit {
      border: 1px solid #A0A3D0;
      position: absolute;
      bottom: -28px; left: 0;
      width: 100%;
      border-radius: 10px;
    }
  }

  &--editTime {
    .zayaInput__wrapper {
      position: relative;
      overflow: visible;
      &:before {
        top: -1px !important;
      }
    }
  }

  &--select {
    .zayaInput__wrapper {
      border: 0;
      overflow: visible;
    }
  }

  &--textarea {
    .zayaInput__wrapper {
      height: 100px;
      overflow-y: scroll;
    }

    p {
      word-break: break-word;
      white-space: normal !important;
    }
  }

  &--calendar {
    input {
      width: 100%;
      height: 51px;
      border: 0;
      background: transparent;
      padding: 0 1rem;
      font-family: 'Avenir Next';
      font-size: 1rem;
      letter-spacing: 0.02em;
      color: #000000;
      white-space: nowrap;
      outline: none;
    }
  }

  &--location {
    position: relative;
    .zayaInput__wrapper {
      button {
        padding: 0 0.5rem;
        margin-right: 1rem;
      }
      input {
        padding-right: 80px !important;
      }
    }

    button.edit {
      border: 0;
      background: transparent;
      padding: 0;
      cursor: pointer;
    }
  
    .conferencingLink {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: space-between;
      height: 100%;
      padding: 0 1rem 0;
      cursor: pointer;
      h5 {
        margin: 0;
        color: #000000;
        font-weight: 500;
      }
      p {
        margin: 0;
        font-size: .75rem;
        white-space: nowrap;
      }
      &:hover {
        background: #FAFAFA;
      }
    }
  }

  .readOnlyValue {
    padding: 0 1rem;
    font-size: 1rem;
    letter-spacing: 0.02em;
    color: #000000;
    white-space: nowrap;
    margin: 0
  }

  input[type="text"], input[type="email"], input[type="number"], input[type="tel"], input[type="password"] {
    flex: 1;
    height: 100%;
    border: 0;
    background: transparent;
    outline: none;
    padding: 0 1rem;
    font-family: $base-font-family;
    font-size: 1rem;
    letter-spacing: 0.02em;
    color: #000000;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-sizing: border-box;

    &:readonly {
      cursor: pointer;
    }
  }

  &__label {
    font-size: .75rem;
    font-weight: 500;
    color: #000000;
    pointer-events: none;
    margin: 0 0 0.5rem;
    display: inline-block;
    @include bp(Mama) {
      font-size: 1rem;
    }
  }

  &__datePicker {
    // padding: 0 1rem 0 2.75rem !important;

    &__close {
      position: absolute;
      top: 50%; right: 0px;
      transform: translateY(-50%);
      background: transparent;
      border: 0;
      cursor: pointer;
      svg, path {
        stroke: #DFE0EF;
      }
      &:hover {
        svg, path {
          stroke: #151625;
        }
      }
    }
  }

  &__calendar {
    label {
      padding-left: 1.75rem;
    }
    &.zayaInput__focused {
      label {
        padding-left: 0;
      }
    }
    .react-datepicker-popper {
      z-index: 10;
    }
  }

  &__opened {
    .zayaInput__wrapper {
      transition: none;
      &::before {
        content: '';
        position: absolute;
        top: 0; left: 0;
        width: var(--marker-width); height: 1px;
        background: #FFF;
        z-index: 1;
      }
    }
    .zayaInput__label {
      z-index: 2;
      transition: none;
      top: 0; left: 10px;
      font-size: .65rem;
      @include bp(Mama) {
        font-size: .75rem;
      }
    }
  }
}

.zayaDropdown {
  position: relative;
  border: 1px solid #A0A3D0;
  border-radius: 20px;
  background: #FFF;
  transition: $base-transition;

  &__label {
    position: absolute;
    top: 50%; left: 1rem;
    transform: translateY(-50%);
    z-index: 1;
    font-size: .85rem;
    font-weight: 600;
    color: #000000;
    transition: $base-transition;
    @include bp(Mama) {
      font-size: 1rem;
    }
  }
  
  &__select {
    content: '';
  }

  &__option {
    padding: .75rem 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    input[type="checkbox"] + label {
      font-size: 1rem;
    }
  }

  &__focused {
    border-color: #6066B0;
    border-top-left-radius: 0;
    &::before {
      content: '';
      position: absolute;
      top: -1px; left: 0;
      width: var(--marker-width); height: 1px;
      background: #F5F6FA;
    }
    .zayaDropdown__label {
      top: 0; left: 10px;
      font-size: .65rem;
      @include bp(Mama) {
        font-size: .75rem;
      }
    }
  }

  &__additional {
    background: #DFE0EF;
    color: #6066B0;
    width:28px;
    height: 28px;
    border-radius: 8px;
    @include unicorn;
    line-height: 0;
  }

  &__menu-list {
    max-height: 100%;
  }
}