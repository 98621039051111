.inputRow {
  &--2up {
    @include flex;
    align-items: flex-start;
    .editEventInput {
      width: 49%;
      margin-right: 1rem;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .zayaDropdown {
    width: 100%;
  }
  .paymentInput {
    padding: 0;
  }
}

.webContainer {
  display: block;

  @media screen and (max-width: 1024px) {
    display: none;
  }
}

.mobileContainer {
  display: block;
  height: 100%;

  @media screen and (min-width: 1024px) {
    display: none;
  }
}

.calendar {
  width: 100%;
  background: transparent !important;

  &__container {
    width: 100%;
    @include flex;
    align-items: flex-start;
    &--displayEvent {
      .calendar__eventEditor {
        width: 55%;
      }
    }
  }

  .mbsc-calendar-header, .mbsc-schedule-wrapper, .mbsc-schedule-all-day-wrapper, .mbsc-schedule-grid-wrapper {
    background: transparent !important;
  }

  .mbsc-schedule-event-range {
    display: none;
  }

  .eventRange {
    font-size: 0.85em !important;
    font-weight: 400;
    color: #FFF !important;
  }

  .calendarHeader {
    @include flex;
    width: 100%;
    padding: 0 .5rem;
    min-height: 56px;
    font-family: $base-font-family;

    &__arrowNav {
      @include flex;
      background: $lightGrey;
      border-radius: 6px;
      .arrow {
        border: 0;
        background: transparent;
        svg {
          margin-top: 1px;
        }
        &--left {
          border-right: 2px solid #FFF;
        }
        &--right {
          border-left: 2px solid #FFF;
        }
      }
      .mbsc-calendar-button {
        border-radius: 0;
        font-family: $base-font-family;
        &.mbsc-calendar-button-today {
          padding: 0 10px;
          font-size: .85rem !important;
          color: #000;
        }
      }
    }

    &__monthNav {
      .mbsc-calendar-button {
        .mbsc-calendar-title {
          color: $grey5;
          font-size: 1rem;
        }
        .mbsc-calendar-title.mbsc-calendar-month {
          color: $grey5;
          font-size: 1rem;
        }
        .mbsc-calendar-title.mbsc-calendar-year {
          color: $grey5;
          font-size: 1rem;
        }
      }
    }

    &__picker {
      .mbsc-segmented {
        background: transparent;
        .mbsc-segmented-item {
          padding: 0 10px;
          &:before {
            display: none;
          }
          .mbsc-segmented-button {
            color: $grey5;
            font-family: $base-font-family;
            &.mbsc-selected {
              color: #FFF;
            }
          }
          .mbsc-segmented-selectbox-inner {
            background: $basePurple;
          }
        }
      }
    }

    &__searchSettings {
      @include flex;
      justify-content: flex-end;
      & > * {
        margin-right: .5rem;
        &:last-child {
          margin-right: 0;
        }
      }
      input {
        display: none;
      }
      button.icon {
        width: 2rem; height: 2rem;
        border-radius: 50%;
        border: 0;
        background: $basePurple;
        cursor: pointer;
        i {
          color: #FFF;
        }
      }
      button.pill {
        padding: 0 .5rem;
        height: 2rem;
        border-radius: 1rem;
        border: 0;
        background: $basePurple;
        cursor: pointer;
        color: #FFF;

        &--clear {
          border: 1px solid $basePurple;
          color: $basePurple;
          background: #FFF;
        }
      }
    }
  }

  .header-template-container {
    .header-template-date {
      .header-template-day-name {
        text-align: left;
        font-size: .625rem;
        color: $grey5;
        font-weight: 600;
        line-height: .75rem;
        text-transform: uppercase;
        font-family: $base-font-family;
      }
      .header-template-day {
        text-align: left;
        font-weight: 500;
        font-size: 22px;
        line-height: 32px;
        font-family: $base-font-family;
      }
    }
  }

  .mbsc-selected {
    .header-template-container {
      .header-template-date {
        .header-template-day-name {
          color: $basePurple;
          font-family: $base-font-family;
        }
        .header-template-day {
          color: $basePurple;
          font-family: $base-font-family;
        }
      }
    }
  }

  .mbsc-schedule-event-background {
    opacity: 1 !important;
  }

  &__overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 999;
  }

  &__eventEditor {
    background: #FFF;
    overflow-y: scroll;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999;
    height: var(--vh, 100vh);
    box-shadow: -1px 4px 4px rgba(0, 0, 0, 0.25);
    padding: 4.313rem 1.875rem 7rem;

    &--float {
      position: static;
      top: auto; right: auto;
      height: auto;
      box-shadow: none;
      padding: 2rem 1.875rem 4.5rem;
    }

    @media screen and (max-width: 1024px) {
      padding: 1.5rem;
    }

    &--noShadow {
      box-shadow: none;
    }

    &__mask {
      position: fixed;
      top: 0; left: 0;
      width: 100%; height: 100vh;
      background: rgba(255,255,255,.5);
      z-index: 998;
    }

    &--modal {
      position: absolute;
    }

    &__header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 34px;

      @media screen and (max-width: 1024px) {
        flex-direction: column-reverse;
        margin-bottom: 2rem;
      }

      &__headerWrapper {
        flex-direction: column;
        width: 100%;

        @media screen and (max-width: 1024px) {
          margin-top: 1.5rem;
        }

        h2 {
          margin-top: 0;
          margin-bottom: 0;
          @include flex;
          font-family: "Source Sans Pro", sans-serif !important;
          font-size: 40px !important;
          padding-left: 0px !important;
          color: #000000;

          @media screen and (max-width: 1024px) {
            font-family: 'Avenir Next';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 29px;
            color: #000000;
          }

          span {
            font-family: 'Avenir Next';
            font-size: 1rem;
            font-weight: 700;
          }
        }

        p {
          margin: 1rem 0 0;
          font-style: normal;
          font-weight: 400;
          font-size: 0.875rem;
          letter-spacing: 0.02em;
          color: #4F4F4F;

          span.cancelReason {
            color: #EB5757;
          }

          @media screen and (max-width: 1024px) {
            font-family: 'Avenir Next';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            letter-spacing: 0.02em;
            color: #4F4F4F;
            margin: .5rem 0 0;
          }
        }
      }

      &__cancellationBox {
        width: 100%;
        padding: 8px;
        box-sizing: border-box;
        background: #F2F2F2;
        margin-top: 20px;

        font-family: 'Avenir Next';
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        letter-spacing: 0.02em;
        color: #4F4F4F;

        span {
          color: #0A148D;
          cursor: pointer;
        }
      }
    }

    &__apptParagraph {
      font-family: 'Avenir Next';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.02em;
      color: #000000;
      margin: 13px 0 0;
    }

    h2 {
      margin-top: 0;
      margin-bottom: 0;
      @include flex;
      font-family: 'Big Caslon';
      font-weight: 500;
      font-size: 1.5rem;
      padding-left: 0px !important;
      color: $baseBlue;

      @media screen and (max-width: 1024px) {
        font-family: 'Avenir Next';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 29px;
        color: #000000;
      }

      span {
        font-family: 'Avenir Next';
        font-size: 1rem;
        font-weight: 700;
      }
    }

    p {
      margin: .5rem 0 0;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.02em;
      color: #000000;
      margin: 13px 0 0;
    }

    &__editError {
      background: rgba(235, 87, 87, 0.1);
      border-radius: 4px;
      padding: 1rem;
      box-sizing: border-box;
      margin-bottom: 24px;

      font-family: 'Avenir';
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: #EB5757;
    }

    &__headerInfo {
      margin: 0 0 1.5rem;
      padding-bottom: 1.5rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);

      @media screen and (max-width: 1024px) {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        border-bottom: none;
        padding-bottom: 0;
        padding-top: 2rem;
        margin: 0 0 2rem;
      }

      h3 {
        font-style: normal;
        font-weight: 600;
        font-size: 1.438rem;
        letter-spacing: 0.02em;
        color: #000000;
        margin: 0 0 0.5rem;

        @media screen and (max-width: 1024px) {
          font-family: 'Avenir Next';
          font-weight: 500;
          font-size: 1.25rem;
          color: #000000;
        }
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 1rem;
        letter-spacing: 0.02em;
        color: #000000;
        margin: 0;

        @media screen and (max-width: 1024px) {
          font-family: 'Avenir';
          font-weight: 500;
          font-size: 0.875rem;
          color: #828282;
        }
      }
    }

    &__actions {
      @include flex;
      justify-content: flex-start;

      & > div {
        width: 100%;
      }
      button.button--accept {
        margin-right: 2rem
      }
    }

    &__actionsRow {
      width: 100%;
      @include flex;
      justify-content: space-between;

      & > div {
        width: 100%;
      }
      @media screen and (max-width: 1024px) {
        flex-direction: column;
        gap: 12px;

        & > div {
          display: flex;
          flex-direction: column;
          gap: 12px;
        }
      }

      button.button--accept {
        margin-right: 2rem;

        @media screen and (max-width: 1024px) {
          margin-right: 0;
        }
      }
    }

    &__actionsRowUpcoming {
      width: 100%;
      @include flex;
      justify-content: space-between;

      & > div {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      @media screen and (max-width: 1024px) {
        flex-direction: column;
        gap: 12px;

        & > div {
          display: flex;
          flex-direction: column-reverse;
          align-items: unset;
        justify-content: unset;
          gap: 12px;
        }
      }

      button.button--accept {
        margin-right: 2rem;

        @media screen and (max-width: 1024px) {
          margin-right: 0;
        }
      }
    }

    .button {
      font-size: 15px;
      font-weight: 500;

      @media screen and (max-width: 1024px) {
        font-size: 1rem;
        font-weight: 400;

        &--clearMobile {
          width: 100%;
        }
      }
    }

    &__addClient {
      width: 100%;

      h3 {
        font-family: 'Big Caslon';
        font-style: normal;
        font-weight: 500;
        font-size: 24px;
        color: #000000;
        margin-top: 0px;
        margin-bottom: 0px;

        @media screen and (max-width: 1024px) {
          font-family: 'Avenir Next';
          font-style: normal;
          font-weight: 600;
          font-size: 24px;
          color: #000000;
          margin-bottom: 0px;
        }
      }

      h4 {
        font-family: $base-font-family;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #000000;
        margin-top: 0px;
        margin-bottom: 1rem;

        @media screen and (max-width: 1024px) {
          font-family: 'Avenir Next';
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          letter-spacing: 0.02em;
          color: #000000;
          margin-bottom: 16px;
        }
      }

      .archiveClient {
        display: flex;
        width: 100%;
        justify-content: flex-end;
      }

      .paymentLabel {
        font-family: 'Avenir Next';
        font-style: normal;
        font-weight: 600;
        font-size: 1rem;
        line-height: 22px;
        letter-spacing: 0.02em;
        color: #000000;
        margin-top: 32px;
      }

      p {
        font-family: $base-font-family;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
      }

      .buttonContainerModal {
        display: flex;
        flex-direction: column;
        background-color: transparent;
        width: 100%;
      }

      .divider {
        width: 109.3%;
        min-width: 109.3%;
        min-height: 1.2px;
        background-color: #ebebeb;
        margin-bottom: 24px;
        margin-left: -24px;
      }

      .buttonFull {
        bottom: 24px;
        width: fit-content;
        padding: 0 16px;
        height: 48px;
        border: none;
        background: #0D1C8B;
        border-radius: 10px;
        font-size: 16px;
        font-family: $base-font-family;
        color: #FFFFFF;
        font-weight: 500;
        cursor: pointer;

        &:disabled {
          cursor: default;
          opacity: .3;
          &:hover {
            background: #0D1C8B;
            color: #FFF;
          }
        }
      }

      &__addInformationBox {
        width: 100%;
        background: #F5F6FA;
        border-radius: 8px;
        padding: 1rem;
        box-sizing: border-box;
        margin-top: 24px;
        display: flex;
        align-items: center;
        gap: 1rem;

        &__addInfomationIcon {
          min-width: 40px;
          max-width: 40px;
          min-height: 40px;
          max-height: 40px;
        }

        &__addInfomationText {
          font-family: 'Avenir Next';
          font-weight: 500;
          font-size: 14px;
          line-height: 19px;
          color: #4F4F4F;

          span {
            cursor: pointer;
            color: #2F80ED;
          }
        }
      }
    }
  }

  &__infoWrapper {
    display: flex;
    flex-direction: column;

    &__label {
      font-style: normal;
      font-weight: 500;
      font-size: 0.75rem;
      letter-spacing: 0.02em;
      color: #000000;
      margin: 0 0 0.5rem;
    }

    &__infoText {
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      letter-spacing: 0.02em;
      color: #000000;
      margin: 0;

      @media screen and (max-width: 1024px) {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    &__editButton {
      border: none;
      background: transparent;
      cursor: pointer;

      @media screen and (max-width: 1024px) {
        display: none;
      }
    }

    &__editButtonMobile {
      border: none;
      background: transparent;
      cursor: pointer;

      font-family: 'Avenir Next';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.02em;
      color: #2F80ED;

      @media screen and (min-width: 1024px) {
        display: none;
      }
    }

    &__message {
      font-style: normal;
      font-weight: 400;
      font-size: 0.875rem;
      letter-spacing: 0.02em;
      color: #000000;
    }

    &__typeOptions {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;

      @media screen and (max-width: 1024px) {
        flex-direction: row;
        gap: unset;
        align-items: center;
        justify-content: space-between;
      }
    }

    &__radioWrapper {
      display: flex;
      align-items: center;
      &__editEventRadioOption {
        width: 20px;
        margin-left: 0px;
        -ms-transform: scale(1.5);
        -webkit-transform: scale(1.5);
        transform: scale(1.5);
        margin-right: 10px;
        cursor: pointer;
      }
    }
  }

  &__hideMobile {
    display: block;
    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  &__divider {
    width: 100%;
    height: 1px;
    background: rgba(0, 0, 0, 0.1);
    margin: 0 0 1.5rem;
  }

  &__warning {
    font-style: normal;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 19px;
    letter-spacing: 0.02em;
    color: #C67C77;
    margin-bottom: 1rem;
  }
}

.inputEvent {
  width: 400px;
  font-family: 'Big Caslon';
  color: #0D1C8B;
  font-size: 25px;
  padding-bottom: 8px;
  border: none;
  border-bottom: 1.2px solid #D0D0D3;
}

.withMarginTop {
  @media screen and (max-width: 1024px) {
    margin-top: 2rem;
  }
}

.eventEditorSave {
  margin-right: 20px;
}

.editEventActions {
  position: fixed;
  bottom: 0;
  padding-bottom: 40px;
  padding-top: 20px;
  background-color: #fff;
  width: calc(55% - 60px);

  &--alignRight {
    display: flex;
    justify-content: flex-end;
  }
}

.editEventPill {
  display: flex;
  justify-content: center;
  color: #18181B;
  font-weight: 500;
  font-family: $base-font-family;
  background: #fff;
  border: 1px solid #BDBDBD;
  border-radius: 6px;
  padding: 12px;
  min-width: 180px;
  max-width: 200px;
  font-size: 16px;
  height: 48px;
  margin-right: 5px;
  position: relative;
  cursor: pointer;

  @media screen and (max-width: 1024px) {
    min-width: unset;
    max-width: unset;
    height: 48px;
    justify-content: unset;
    color: #000000;
    font-weight: 400;
    background: rgba(13, 28, 139, 0.05);
    border: none;
    border-radius: 8px;
    padding: 12px 16px;
    margin-right: 0;
  }
}

.react-datepicker__tab-loop {
  position: absolute;
  top: 0;
}

.time {
  padding-left: 20px;
  padding-right: 20px;
  min-width: 115px;
  text-transform: lowercase;
}

.timeClaim {
  padding-left: 20px;
  padding-right: 20px;
  min-width: 146px;
  text-transform: lowercase;
  border-color: rgba(0, 0, 0, 0.2);
}

.editEventMonthPicker {
  font-weight: 600;
  font-size: 14px;
  color: #0D1C8B;
  display: flex;
  align-items: center;
  margin-right: 24px;
  cursor: pointer;
  position: relative;
}

.editEventYearPicker {
  font-weight: 600;
  font-size: 14px;
  color: #19181A;
  display: flex;
  align-items: center;
  margin-right: 24px;
  cursor: pointer;
  position: relative;
}

.arrow-down {
  border: solid #0D1C8B;
  border-width: 0 1.5px 1.5px 0;
  display: inline-block;
  padding: 2px;
  margin-bottom: 1.5px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  margin-left: 8px;
}

.yearPicker {
  border: solid #acacac;
  border-width: 0 1.5px 1.5px 0;
}

.editEventHeaderDropDown {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 0px;
  background: #FFFFFF;
  box-shadow: 0px 30px 84px rgba(19, 10, 46, 0.08);
  border-radius: 8px;
  position: absolute;
  top: 16px;
  left: 0px;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 0px;
  width: 112px;
  max-height: 300px;
  overflow: auto;
}

.editEventHeaderDropDownOption {
  margin-bottom: 10px;
  font-weight: 400;
  font-size: 14px;
  color: #19181A;
  width: 100%;
  text-align: left;
  padding: 4px 16px 4px 16px;

  &:hover {
    background-color: #F8F7FA;
    color: #0D1C8B;
    font-weight: 600;
  }
}

.react-datepicker__day--today {
  background-color: #fff !important;
  color: #000 !important;
}

.react-datepicker__day--keyboard-selected {
  background-color: #fff !important;
  color: #000 !important;
  font-weight: normal;
}

.react-datepicker__day--outside-month {
  color: #a3a3a3 !important;
}

.editEventHeaderDropDownOption.selected {
  background-color: #0D1C8B;
  font-weight: 600;
  color: #fff;
}

.editEventDatePicker {
  background: #FFFFFF;
  border: 1px solid rgba(13, 28, 139, 0.13);
  box-sizing: border-box;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  margin-top: 6px;
}

.editEventTimePicker {
  width: 175px;
  height: 180px;
  background: #FFFFFF;
  border: 1px solid rgba(13, 28, 139, 0.13);
  box-sizing: border-box;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  overflow-y: auto;
  padding: 6px;
  position: absolute;
  top: 45px;
  left: 0px;
  display: flex;
  flex-direction: column;
  z-index: 999;

  @media screen and (max-width: 1024px) {
    left: unset;
    right: 0;
    top: 53px;
  }
}

.editEventTimeOption {
  width: 100%;
  padding: 10px;
  color: #0A148D;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  &:hover {
    background: rgba(13, 28, 139, 0.13);
    border-radius: 2px;
  }
}

.cancellationMessage {
  font-family: 'Avenir Next';
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: #000000;
  margin-top: 32px;
}

.editEventRow {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
  input:focus{
    outline: none;
  }

  &--gap {
    justify-content: space-between;
  }

  &--full {
    display: block;
  }

  &--centerAlign {
    align-items: center;
  }

  @media screen and (max-width: 1024px) {
    margin-bottom: 12px;

    &--columnMobile {
      flex-direction: column;
      gap: 12px;
      width: 100%;
    }
  }
}

.eventTimeDatePicker {
  max-width: 200px;

  @media screen and (max-width: 1024px) {
    max-width: unset;
  }
}

.editEventSpan {
  font-weight: 600;
  margin-right: 10px;
  margin-left: 6px;
}

.visitLocationWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 8px;

  .widthWrapper {
    width: 100%;
  }

  .unitInput {
    width: 133px;
    height: 48px;
    padding: 0 8px;
    box-shadow: none;
    outline: none;
    border: 1px solid #BDBDBD;
    border-radius: 10px;
    background: transparent;
    font-family: 'Avenir Next';
    font-size: 16px;
    color: #333333;

    &::placeholder {
      color: #808080;
    }
  }
}

.react-datepicker__header {
  background-color: #fff !important;
  border: none !important;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
}

.react-datepicker__triangle {
  display: none !important;
}

.react-datepicker {
  background-color: #fff !important;
  border: none !important;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 20px;
  padding-top: 10px;
}

.react-datepicker__day:hover {
  border-radius: 100px !important;
  color: #0A148D !important;
  font-weight: 600 !important;
}

.react-datepicker__day--selected {
  background-color: #0A148D !important;
  font-weight: 600 !important;
  border-radius: 100px !important;
  &:hover {
    color: #fff !important;
  }
  &:focus-visible {
    outline: none !important;
  }
}

.react-datepicker__day--today.react-datepicker__day--selected {
  color: #fff !important;
}

.editEventDatePickerHeader {
  margin: 10;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  background-color: #fff;

}

.invalidEvent {
  text-decoration: line-through;
  color: #fff !important;
}

.mbsc-event-time {
  font-size: 0 !important;
  margin-bottom: 40px;
}

.mbsc-schedule-event-title {
  color: #FFF !important;
  .blocked-status {
    color: #000 !important;
  }
}

.eventStartTime {
  font-size: .875em;
  line-height: 1.428572em;
  position: absolute;
  right: 17px;
  top: 16px;
}

.eventEndTime {
  opacity: .7;
  font-size: .875em;
  line-height: 1.428572em;
  position: absolute;
  right: 17px;
  padding-bottom: 22px;
  top: 36px;
}

div[data-id^='mbsc'], div[data-id*=' mbsc']{
  .mbsc-schedule-event-background {
    background-color: #C4C4C4 !important;
  }

  .mbsc-schedule-event-title {
    color: #000 !important;
  }

  .eventRange {
    color: #000 !important;
  }
}

.buttonContainer {
  position: fixed;
  top: 0; left: 0;
  z-index: 100;
  width: 100%;
  padding-left: 290px;
  background: #FFF;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 27px;

  body.admin-mode & {
    top: 71px;
  }
}

.buttonAddEvent {
  height: 50px;
}

.eventEditorHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 50px;

  h2 {
    font-size: 40px !important;
    font-family: 'Source Sans Pro', sans-serif !important;
  }

  &__rowWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
  }
}

.eventEditorCloseIcon {
  cursor: pointer;
}

.errorMessage {
  color: red;
  font-size: 14px;
  font-weight: bold;
  margin: 0;
  margin-top: 20px;
  width: 80%;
  padding: 0;
}

.apptmTypeTag {
  width: 16px;
  height: 16px;
  border-radius: 2px;
  margin-right: 10px;
}

.apptmTypeTag.newEvent {
  background: #2F80ED;
}

.apptmTypeTag.blockedEvent {
  background: #E0E0E0;
}

.editEventApptmTypePicker {
  display: flex;
  position: relative;
  flex-direction: row;
  padding-left: 12px;
  padding-right: 15px;
  font-size: 15px;
  font-family: $base-font-family;
  color: #18181B;
  font-weight: 500;
  align-items: center;
  width: 278px;
  height: 48px;
  border: 1px solid #BDBDBD;
  border-radius: 10px;
  cursor: pointer;
  svg {
    margin-left: auto;
    transform: scale(1.5);
    path {
      stroke-width: 1.3px;
      stroke: #BDBDBD !important;
    }
  }
  .editEventApptmTypePickerDropDown {
    width: 278px;
    z-index: 999;
    background: #fff;
    border: 1px solid #BDBDBD;
    border-radius: 10px;
    overflow-y: auto;
    position: absolute;
    padding-top: 5px;
    padding-bottom: 5px;
    top: 50px;
    left: 0px;
    display: flex;
    flex-direction: column;

    .editEventApptmTypeOption {
      width: 100%;
      display: flex;
      flex-direction: row;
      padding: 10px;
      font-size: 15px;
      font-family: $base-font-family;
      color: #18181B;
      cursor: pointer;
      &:hover {
        background: #F4F4F5;
      }
    }
  }
}

.editEventDivider {
  width: 100%;
  min-width: 100%;
  min-height: 1.2px;
  background-color: #ebebeb;
  margin-top: 32px;
  margin-bottom: 24px;
}

.blockedEventText {
  width: 80%;
  font-family: $base-font-family;
  font-weight: 400;
  font-size: 15px;
  color: #828282;
  margin-bottom: 10px !important;
}

.editEventLabel {
  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  margin: 0 0 0.5rem !important;
}

.editEventInput {
  display: flex;
  flex-direction: column;

  &--width {
    width: 49%;
  }

  &--fullWidth {
    width: 100%;
  }

  &--widthMobile {
    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  .editEventLabel {
    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    margin: 0 0 0.5rem !important;
  }

  .cardNumberContainer {
    height: 60px;
    width: 100%;
    padding: 0 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #F2F2F2;
    border-radius: 8px;

    .cardNumber {
      font-family: 'Avenir Next';
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      letter-spacing: 0.02em;
      color: #4F4F4F;
    }

    .cardUpdate {
      font-family: 'Avenir Next';
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      letter-spacing: 0.02em;
      color: #0D1C8B;
      cursor: pointer;
    }
  }

  .editEventRadioOptions {
    display: flex;
    flex-direction: row;
    font-size: 15px;
    font-family: $base-font-family;
    color: #18181B;
    font-weight: 500;
    span {
      margin-top: 1px;
      margin-right: 16px;
    }
  }

  .editEventRadioOption {
    width: 20px;
    margin-left: 0px;
    -ms-transform: scale(1.5);
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
    margin-right: 10px;
    cursor: pointer;
  }

  .editEventVisitLocationInput {
    width: 500px;
    height: 48px;
    border: 1px solid #BDBDBD;
    border-radius: 10px;
    padding-left: 12px;
    font-weight: 400;
    font-size: 15px;

  }

  .editEventMessageTextarea {
    width: 500px;
    height: 48px;
    border: 1px solid #BDBDBD;
    border-radius: 10px;
    padding: 12px;
    font-family: sans-serif;
    font-weight: 400;
    font-size: 15px;
    min-height: 150px;
    resize: none;
    outline: none;

    &::placeholder {
      color: #828282;
      font-weight: 400;
      font-size: 15px;
    }
  }

  .editEventVisitLocationInput::placeholder {
    color: #828282;
    font-weight: 400;
    font-size: 15px;
  }

  .editEventClientInput {
    width: 300px;
    height: 48px;
    border: 1px solid #BDBDBD;
    border-radius: 10px;
    padding-left: 12px;
    font-weight: 400;
    font-size: 15px;
  }

  .editEventClientInput::placeholder {
    color: #828282;
    font-weight: 400;
    font-size: 15px;
  }

  .editAddClientInput {
    width: 255px;
    margin-right: 11px;
    height: 48px;
    border: 1px solid #BDBDBD;
    border-radius: 10px;
    padding-left: 12px;
    font-weight: 400;
    font-size: 15px;
    outline: none;

    input {
      height: 100%;
      width: 100%;
      border: none;
      border-radius: 10px;
      outline: none;
      font-weight: 400;
      font-size: 15px;
    }

    &--width {
      width: 100%;
      margin-right: 0;
    }

    &:disabled {
      background: #F2F2F2;
      border: 1px solid #F2F2F2;
      cursor: not-allowed;
    }

    &:read-only {
      cursor: default;
    }

    @media screen and (max-width: 1024px) {
      border: 1px solid #D9D9D9;
      border-radius: 4px;
    }
  }

  .editAddClientInputError {
    border: 1px solid #F04444;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  .editAddClientInput::placeholder {
    color: #828282;
    font-weight: 400;
    font-size: 15px;
  }

  .hidePlaceholderWeb::placeholder {
    color: transparent;

    @media screen and (max-width: 1024px) {
      font-family: 'Avenir Next';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      letter-spacing: 0.02em;
      color: #828282;
    }
  }

  .editAddClientInputCard {
    display: flex;
    width: 350px;
    margin-right: 11px;
    height: 48px;
    border: 1px solid #BDBDBD;
    border-radius: 10px;
    overflow: hidden;
    padding-left: 15px;

    input {
      font-weight: 400;
      font-size: 15px;
      width: 20%;
      text-align: center;
      border: none;


      &:first-child {
        width: 60%;
        text-align: left;
      }
    }
  }

  .editAddClientStripe {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 15px;
    width: 100%;
    height: 48px;
    border: 1px solid #BDBDBD;
    border-radius: 10px;
    margin-bottom: 8px;

    & > div {
      width: 100%;
    }

    @media screen and (max-width: 1024px) {
      border: 1px solid #D9D9D9;
      border-radius: 4px;
    }
  }

  .editAddClientInputCard::placeholder {
    color: #828282;
    font-weight: 400;
    font-size: 15px;
  }

  .editAddClientInputBillingZip {
    width: 160px;
    margin-right: 11px;
    height: 48px;
    border: 1px solid #BDBDBD;
    border-radius: 10px;
    padding-left: 12px;
    font-weight: 400;
    font-size: 15px;
  }

  .editAddClientInputBillingZip::placeholder {
    color: #828282;
    font-weight: 400;
    font-size: 15px;
  }
}

.addClientButton {
  font-family: $base-font-family;
  font-weight: 500;
  font-style: normal;
  font-size: 15px;
  color: #2F80ED;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 17px;
}

.eventEditorCloseHeader {
  cursor: pointer;
  position: absolute;
  right: 16px;
  top: 16px;

  @media screen and (max-width: 1024px) {
    position: relative;
    top: unset;
    right: unset;

    width: 100%;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.eventEditorCloseIconModal {}

.suggestionTitle {
  font-family: $base-font-family;
  font-weight: 500;
  font-size: 16px;
  padding-left: 25px;
  line-height: 19px;
  color: #2F80ED;
  margin-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #D0D0D3;
  cursor: pointer;
}

.suggestionText {
  font-family: 'Avenir Next';
  font-weight: 400;
  font-size: 16px;
  color: #5A5A65;
  padding: 10px 25px;
  cursor: pointer;

  &:hover {
    background: #D0D0D3;
  }
}

.suggestionsList {
  list-style-type: none;
  padding-left: 0;
}

.autoSuggestContainer {
  background: #FFFFFF;
  border: 1px solid #D0D0D3;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08);
  border-radius: 20px;
  width: 450px;
  height: 180px;
  overflow-y: auto;
  position: absolute;
  margin-top: 5px;
  z-index: 9999;
}

.userErrorContainer {
  margin: 12px 0 0;
}

.userErrorContainer {
  display: flex;
  gap: 6px;
  max-width: 500px;

  @media screen and (max-width: 1024px) {
    max-width: 100%;
  }

  &--margin {
    margin-bottom: 1rem;
  }

  &__warningIcon {
    width: 28px;
    height: 28px;
  }
}

.selectedUserError {
  font-family: 'Source Sans Pro', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #000;
  max-width: 500px;
  line-height: normal;
  letter-spacing: 0.28px;

  @media screen and (max-width: 1024px) {
    max-width: unset;
  }
}

.inputError {
  color: #EB5757;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  margin-top: 8px;
}

.zayaDropdown {
  position: relative;
  width: 500px;
  height: 48px;
  border: none !important;

  &__select {
    content: '';
  }

  &__option {
    padding: .75rem 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    input[type="checkbox"] + label {
      font-size: 1rem;
    }
  }

  &__additional {
    background: #DFE0EF;
    color: #6066B0;
    width:28px;
    height: 28px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 0;
  }

  &__menu-list {
    max-height: 100%;
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}

.errorMessageTime {
  color: #EB5757 !important;
  font-size: 12px;
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 500;
  margin: 0;
  padding: 0;
  margin-top: 32px;
}

.noCCDivider {
  margin-right: 10px;
  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #0D1C8B;
}

.dividerBlue {
  height: 1px;
  background-color: #0D1C8B;
  width: 100%;
}

.noCCText {
  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}

.noCCTitle {
  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.infoIcon {
  position: absolute;
  top: 2px;
  left: 1px;
}

.editEventRadioOption {
  width: 20px;
  margin-left: 0px;
  -ms-transform: scale(1.5);
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
  margin-right: 10px;
  cursor: pointer;
}

.paymentInput {
  padding-top: 18px;
  padding-left: 32px;
}

.paymentNoCCInfo {
  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #828282;
  padding-left: 32px;
  padding-bottom: 42px;
}

.paymentNoCCInfoPadding {
  padding-bottom: 8px;
}

.paymentNoCCWarning {
  font-family: 'Avenir Next';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #EE4649;
  padding-left: 32px;
  padding-bottom: 42px;
}

.continueButton {
  border: 1px solid #0D1C8B;
  border-radius: 6px;
  padding: 12px 16px;
  font-family: 'Avenir';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  color: #0D1C8B;
  margin-bottom: 42px;
  width: fit-content;
  background: transparent;
  cursor: pointer;

  &:disabled {
    opacity: .4;
    cursor: not-allowed;
  }
}

.seila {
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  background: #ffffff;

  span {
    text-align: left !important;
  }
}

.mobileHeaderRow {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  &__saveBtn {
    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #2F80ED;
    background: transparent;
    border: none;

    &:disabled {
      opacity: 0.4;
    }

    @media screen and (min-width: 1024px) {
      display: none;
    }
  }
}

.mobileUnavailableRow {
  display: flex;
  gap: 21px;

  &__icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
  }

  &__picker-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
  }

  &--duration {
    margin-top: 32px;
    margin-bottom: 30px;
  }

  &__client-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    &__row {
      display: flex;
      width: 100%;
      gap: 21px;
    }

    &__view-client {
      margin-left: 41px;
      font-family: 'Avenir Next';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.02em;
      color: #2F80ED;
    }
  }

  &__locationInput {
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    width: 100%;
    height: 48px;
    padding: 0 16px;
    outline: none;

    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.02em;

    &::placeholder {
      color: #828282;
    }
  }

  &__noteTextarea {
    border: 1px solid #D9D9D9;
    border-radius: 4px;
    width: 100%;
    height: 107px;
    resize: none;
    padding: 14px 16px;
    outline: none;

    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    letter-spacing: 0.02em;

    &::placeholder {
      color: #828282;
    }
  }

  &__placesContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.mobileDateRow {
  display: flex;
  flex: 1;
  gap: 12px;
}

.mobileJustifyRow {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
}

.hideMobile {
  display: block;
  @media screen and (max-width: 1024px) {
    display: none;
  }
}

.hideWeb {
  display: block;
  @media screen and (min-width: 1024px) {
    display: none;
  }
}

.togglesContainer {
  display: flex;
  flex-direction: row;
  gap: 54px;
}

.slideToggleRow {
  display: flex;
  margin-top: 25px;
  margin-left: 5px;
  align-items: center;
  gap: 8px;
  user-select: none;
}

.toggleLabel {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.02em;
  color: #333333;
}
