// This file should only contain references to variables and mixins.

@import 'variables';
@import 'animations';

body, body > div {
  font-family: 'Source Sans Pro', sans-serif;
}

#noPaymentInfoTooltip {
  background-color: #fff;
  opacity: 1;
  border: 1px solid #000 !important;

  &::after {
    border: 1px solid #000 !important;
  }
}