.appointmentActions {
  button {
    width: 100%;
    cursor: pointer;
  }
}

.appointments {
  max-width: 450px;

  &__container {
    @include flex;
    align-items: flex-start;
    margin: 0 auto;
    padding-top: 1rem;
    max-width: 1000px;

    & > div {
      width: 49%;
    }
  }
  
  .pod {
    @include flex;
    border: 2px solid #CCC;
    border-radius: 4px;
    padding: 1rem;
    margin-bottom: 1rem;

    &__left {
      p {
        margin: 0;
      }
      .pod--status {
        font-size: .85rem;
      }
    }

    &__right {
      @include flex;
      justify-content: flex-end;
    }

    .tags {
      @include flex;
      justify-content: flex-end;
      width: 100%;
      margin: 0 1rem 0;
    }

    &:last-child {
      margin-bottom: 0;
    }

    i {
      color: $greyA;
    }

    &:hover {
      background: #FAFAFA;
      cursor: pointer;
      border-color: #333;
      i {
        color: #333;
      }
    }
  }
}

.currentAppointment {
  h3, h5, p {
    margin: 0;
  }
  p.small {
    margin: 0;
    font-size: .7rem;
  }
  header {
    margin-bottom: 1rem;
  }
  section {
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid #CCC;

    h5 {
      margin-bottom: .5rem;
      font-size: .75rem;
      text-transform: uppercase;
      letter-spacing: .02em;
    }

    &:last-child {
      margin-bottom: 0;
      border-bottom: 0;
    }
  }
}