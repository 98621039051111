html {
  // background: $pureBlack;
}

html, body, #root {
  min-height: 100vh;

  @media screen and (max-width: 1024px) {
    min-height: unset;
  }
}

::placeholder {
  color: #DDDDDD;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: $base-font-family;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  color: #222;
  // background: #0f3854;
  // background: radial-gradient(ellipse at center,  #0a2e38  0%, #000000 70%);
  // background-size: 100%;
}

a {
  color: $basePurple;
}

p a {
  color: #000;
  &:hover {
    color: #333;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

h2.big {
  margin-top: 0;
  font-size: 2rem;
}

.error {
  padding: .3rem;
  border-radius: 3px;
  color: #FFF !important;
  background: $baseRed;
  font-size: .75rem !important;
  font-weight: 700 !important;
}

.react-datepicker-wrapper {
  width: 100%;
  height: 100%;
  .react-datepicker__input-container {
    width: 100%;
    height: 100%;
  }
}

.notFound {
  color: #FFF;
  position: absolute;
  top: 50%; left: 50%;
  text-align: center;
  transform: translate(-50%, -50%);
}

.button {
  padding: 0 1rem;
  height: 40px;
  background: $basePurple;
  border: 0;
  border-radius: 8px;
  font-size: 1.25rem;
  font-weight: 700;
  color: #FFF;
  cursor: pointer;
  white-space: nowrap;

  @media screen and (max-width: 1024px) {
    height: 49px;
  }

  @include bp(Mama) {
    font-size: .85rem;
  }

  &--inverted {
    background: transparent;
    border: 1px solid $basePurple;
    color: $basePurple;
  }

  &--red {
    background: $baseRed;
  }

  &--clear {
    color: $basePurple;
    padding: 0;
    background: transparent;
  }

  &--clearRed {
    color: #EF3D3D;
    padding: 0;
    background: transparent;
  }

  &--clearMobile {
    @media screen and (max-width: 1024px) {
      border: 1px solid #BDBDBD;
      color: #EB5757;
    }
  }

  &:disabled {
    opacity: .2;
  }
}

.cancelButton {
  padding: 12px;
  border-radius: 40px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  gap: 4px;
  border: 1px solid #BDBDBD;
  background: #FFF;

  color: #F04444;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.checkBoxes {
  display: flex;
  justify-content: flex-start;

  & > div {
    margin-right: 1rem;
  }
}

.checkbox {
  label {
    position: relative;
    padding-left: 24px;
    cursor: pointer;

    &:before {
      content: '';
      position: absolute;
      top: 50%; left: 0;
      transform: translateY(-50%);
      border: 2px solid #CCC;
      width: 14px; height: 14px;
      border-radius: 3px;
    }
    &:after {
      display: none;
      content: '';
      position: absolute;
      top: 50%; left: 0;
      transform: translateY(-50%);
      border: 2px solid #CCC;
      width: 14px; height: 14px;
      border-radius: 3px;
      background: $basePurple;
    }
  }
  input[type="checkbox"] {
    display: none;
  }

  input[type="checkbox"]:checked + label {
    &:before { display: none; }
    &:after { display: block; }
  }
}

.tags {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: .25rem;
}

.tag {
  margin-right: 5px;
  padding: 4px 6px;
  border: 1px solid $basePurple;
  background: $basePurple;
  text-transform: uppercase;
  font-size: .65rem;
  font-weight: 700;
  color: #FFF;
  border-radius: 3px;
  white-space: nowrap;

  &:last-child {
    margin-right: 0;
  }
  &--purple {
    border-color: $basePurple;
    background: $basePurple;
  }
  &--green {
    border-color: $baseGreen;
    background: $baseGreen;
  }
  &--blue {
    border-color: $baseCyan;
    background: $baseCyan;
  }
  &--yellow {
    border-color: $baseYellow;
    background: $baseYellow;
  }
  &--red {
    border-color: $baseRed;
    background: $baseRed;
  }
}

$switch_width: 70px;
$switch_height: 35px;
$switch_toggle: 31px;

.switch {
  display: flex;
  
  input[type="checkbox"] {
    height: 0;
    width: 0;
    visibility: hidden;
    display: none;
  }
  
  label {
    cursor: pointer;
    text-indent: -9999px;
    width: $switch_width;
    height: $switch_height;
    background: #FF0000;
    display: block;
    border-radius: $switch_height;
    position: relative;

    svg {
      position: absolute;
      top: 50%; left: 50%;
      transform: translate(-50%, -50%);
      z-index: 10;
      pointer-events: none;
    }

    svg.zayaIcon--toggleClose {
      display: inline-block;
      margin-left: -18px;
    }

    svg.zayaIcon--toggleCheck {
      display: none;
      margin-left: 18px;
    }
  }
  
  label:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: $switch_toggle;
    height: $switch_toggle;
    background: #fff;
    border-radius: $switch_toggle;
    transition: 0.3s;
  }
  
  &--active {
    input[type="checkbox"] + label {
      background: $basePurple;
      svg.zayaIcon--toggleClose {
        display: none;
      }
      svg.zayaIcon--toggleCheck {
        display: inline-block;
      }
    }
    input[type="checkbox"] + label:after {
      left: calc(100% - 2px);
      transform: translateX(-100%);
    }
  }
  
  
  label:active:after {
    width: 30px;
  }
}

.radioGroup {
  @include flex;
  justify-content: flex-start;
  &__radio {
    margin-right: .5rem;
    &:last-child {
      margin-right: 0;
    }
    label {
      position: relative;
      padding-left: 26px;
      cursor: pointer;
      &:before {
        content: '';
        position: absolute;
        top: 50%; left: 0;
        transform: translateY(-50%);
        width: 20px; height: 20px;
        border: 1px solid #BDBDBD;
        border-radius: 50%;
      }
      &:after {
        display: none;
        content: '';
        position: absolute;
        top: 50%; left: 5px;
        transform: translateY(-50%);
        width: 12px; height: 12px;
        border-radius: 50%;
        background: $basePurple;
      }
    }
    input {
      display: none;
    }
    input:checked + label:after {
      display: block;
    }
  }
}