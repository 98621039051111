.mask {
  position: fixed;
  top: 0; left: 0;
  width: 100vw; height: var(100vh, 100vh);
  z-index: 1001;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  &__container {
    margin: 0 1rem;
    padding: 2rem;
    max-width: 600px; min-width: 15rem;
    background: #FFF;
    border-radius: 5px;
    text-align: center;
    border: 1px solid $lightPurple;

    h3 {
      margin: 0 0 .5rem;
      text-align: left;
      color: $grey;
    }

    p {
      margin-top: 0;
      font-weight: 700;

      &.message {
        margin: .75rem 0 .5rem;
      }
    }
  }

  &--alert {
    h3 {
      color: $basePurple;
      padding: 0 !important;
    }
    .mask__container {
      max-width: 350px;
      min-width: 300px;
    }
    p {
      text-align: left;
      font-weight: normal;
    }
    .mask__actions {
      button {
        width: 100%;
      }
    }
  }

  &--confirm {
    text-align: left;
    .mask__container {
      h3 {
        color: $basePurple;
        padding: 0 !important;
      }
      p {
        text-align: left;
        font-weight: normal;
      }
    }
    .mask__actions {
      text-align: left;
      button {
        margin-right: 1rem;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

$purpleDotColor: rgba(96, 102, 176, .75);

.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  &:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.5);
  }
  &:not(:required) {
    /* hide "loading..." text */
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
  }
  &:not(:required):after {
    content: '';
    display: block;
    font-size: 10px;
    width: 1em;
    height: 1em;
    margin-top: -0.5em;
    -webkit-animation: spinner 1500ms infinite linear;
    -moz-animation: spinner 1500ms infinite linear;
    -ms-animation: spinner 1500ms infinite linear;
    -o-animation: spinner 1500ms infinite linear;
    animation: spinner 1500ms infinite linear;
    border-radius: 0.5em;
    -webkit-box-shadow: rgba(96, 102, 176, 0.75) 1.5em 0 0 0, rgba(96, 102, 176, 0.75) 1.1em 1.1em 0 0, rgba(96, 102, 176, 0.75) 0 1.5em 0 0, rgba(96, 102, 176, 0.75) -1.1em 1.1em 0 0, rgba(96, 102, 176, 0.5) -1.5em 0 0 0, rgba(96, 102, 176, 0.5) -1.1em -1.1em 0 0, rgba(96, 102, 176, 0.75) 0 -1.5em 0 0, rgba(96, 102, 176, 0.75) 1.1em -1.1em 0 0;
    box-shadow: rgba(96, 102, 176, 0.75) 1.5em 0 0 0, rgba(96, 102, 176, 0.75) 1.1em 1.1em 0 0, rgba(96, 102, 176, 0.75) 0 1.5em 0 0, rgba(96, 102, 176, 0.75) -1.1em 1.1em 0 0, rgba(96, 102, 176, 0.75) -1.5em 0 0 0, rgba(96, 102, 176, 0.75) -1.1em -1.1em 0 0, rgba(96, 102, 176, 0.75) 0 -1.5em 0 0, rgba(96, 102, 176, 0.75) 1.1em -1.1em 0 0;
  }
}

.loadingNoOpacity {
  &:before {
    background-color: rgba(255,255,255,1);
  }
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}