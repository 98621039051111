form.form {
  padding: 0 2rem;
  .form_row {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
    &__block {
      label {
        display: block;
        margin-bottom: .25rem;
        font-size: .75rem;
      }
      input[type="text"], input[type="email"], input[type="number"], input[type="tel"] {
        border: 2px solid #CCC;
        border-radius: 4px;
        padding: 10px;
        width: 100%;

        &:disabled {
          opacity: .4;
        }
      }
    }

    &--2up {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      & > div {
        width: 49%;
      }
    }

    &--companyPicked {
      display: none;
    }
  }

  &--notPreset {
    .form_row--companyPicked {
      display: block;
      &.form_row--2up {
        display: flex;
      }
    }
  }
}