body.layout--home {
  .main__main {
    @include unicorn;
    height: 100vh;
  }
  .homeFloater {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img {
      width: 400px;
    }
    button {
      padding: 0 1rem;
      height: 40px;
      background: $basePurple;
      border: 0;
      border-radius: 8px;
      font-size: 1.25rem;
      color: #FFF;
    }
  }
}