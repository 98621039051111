.requests {
  @media screen and (max-width: 1024px) {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  body.admin-mode & {
    padding-top: 71px;

    @media screen and (max-width: 1024px) {
      padding-top: 0;
    }
  }
  & > header {
    @include flex;
    padding: 48px 48px 40px;

    @media screen and (max-width: 1024px) {
      padding: 1.75rem 1.375rem 0.688rem;
      justify-content: space-between;
      align-items: center;
    }

    h2 {
      margin: 0;
      font-size: 40px;
      line-height: normal;
      font-family: "Source Sans Pro", sans-serif;
      font-weight: 600;
      color: $basePurple;

      @media screen and (max-width: 1024px) {
        font-family: 'Avenir Next';
        font-style: normal;
        font-weight: 600;
        font-size: 2rem;
        color: #000000;
        padding: 0;
      }
    }
  }
  & > section {
    padding: 40px 48px;

    label {
      margin-right: 0.5rem;
      font-size: 1rem;
      font-weight: 600;
      color: $basePurple;
    }

    .react-datepicker {
      border: 1px solid #BDBDBD !important;
      border-radius: 10px;
    }

    .react-datepicker-wrapper {
      input {
        display: flex;
        justify-content: center;
        color: #18181B;
        font-weight: 500;
        font-family: $base-font-family;
        background: #fff;
        border: 1px solid #BDBDBD;
        border-radius: 10px;
        padding: 12px;
        min-width: 180px;
        max-width: 200px;
        font-size: 16px;
        height: 45px;
        margin-right: 5px;
        position: relative;
        cursor: pointer;
      }
    }

    table {
      width: 100%;
      table-layout: fixed;
      border-collapse: collapse;
      tr {
        border-bottom: 1px solid #E0E0E0;
      }

      .incompleteTable {
        th, td {
          &:nth-child(1) { width: 14.5%; }
          &:nth-child(2) { width: 14.5%; }
          &:nth-child(3) { width: 14.5%; }
          &:nth-child(4) { width: 14.5%; }
          &:nth-child(5) { width: 14.5%; }
          &:nth-child(6) { width: 14.5%; }
        }
      }

      .normalTable {
        th, td {
          &:nth-child(1) { width: 17.5%; }
          &:nth-child(2) { width: 17.5%; }
          &:nth-child(3) { width: 17.5%; }
          &:nth-child(4) { width: 17.5%; }
          &:nth-child(5) { width: 17.5%; }
        }
      }

      thead {
        th {
          font-family: "Source Sans Pro", sans-serif;
          padding-bottom: 12px;
          font-size: 14px;
          font-weight: 600;
          color: #828282;
          text-align: left;
        }
      }
      tbody {
        tr {
          .actionButton {
            font-family: "Source Sans Pro", sans-serif;
            font-weight: 600;
            font-size: 1rem;
            line-height: normal;
            color: $basePurple;
            cursor: pointer;
            border-radius: 100px;
            border: 1px solid #BDBDBD;
            background: #FFFFFF;
            padding: 12px;
            box-sizing: border-box;
            text-align: center;
            white-space: nowrap;
          }

          .actionButtonBigger {
            padding: 12px 36px;
          }
        }
        td {
          padding: 22px 0;
          font-family: "Source Sans Pro", sans-serif;
          font-weight: 400;
          font-size: 1rem;
          line-height: 1.375rem;
          color: #000000;
        }

        .visitType {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 8px;

          & > div {
            color: #0D1C8B;
            font-family: "Source Sans Pro", sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.32px;
          }

          & > a {
            color: #0D1C8B;
            font-family: "Source Sans Pro", sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.32px;
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
  }

  .emptyAppointments {
    border-radius: 12px;
    border: 1px solid #E0E0E0;
    background: #F2F2F2;
    width: 100%;
    height: 207px;
    display: flex;
    align-items: center;
    justify-content: center;

    color: #000;
    font-family: "Source Sans Pro", sans-serif;
    font-size: 16px;
    font-weight: 600;
    line-height: normal;
  }

  section.datepickerSection {
    padding: 0 48px 40px;
  }

  .calendar__eventEditor {
    position: fixed;
    top: 0; right: 0;
    width: 55%;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  .sectionContainer {
    height: 100%;

    &--marginTop {
      margin-top: 0.875rem;
    }
  }

  .mobileApptmRequests {
    background: rgba(242, 153, 74, 0.1);
    padding: 1rem 1.375rem;
    margin-top: 1.375rem;

    font-family: 'Avenir Next';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.02em;
    color: #DE7C25;
  }

  .mobileApptmHeader {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 900;
    font-size: 0.875rem;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #0A148D;
    margin-top: 26px;
    margin-bottom: 26px;
    padding: 0 1.375rem;
  }

  .mobileApptmCard {
    background: #FFFFFF;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1.375rem 1rem;
    margin-bottom: 24px;

    .mobileApptmCardLeft {
      display: flex;
      align-items: center;
      gap: 1rem;
    }

    .cardIconPending {
      min-width: 42px;
      min-height: 42px;
      border-radius: 42px;
      background: rgba(242, 153, 74, 0.1);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .cardIcon {
      min-width: 42px;
      min-height: 42px;
      border-radius: 42px;
      background: #EBECF6;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .mobileApptmName {
      font-family: 'Avenir';
      font-style: normal;
      font-weight: 500;
      font-size: 1rem;
      color: #000000;
    }

    .mobileApptmVisit {
      font-family: 'Avenir';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #828282;
    }

    .mobileTime {
      font-family: 'Avenir';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: #000000;
    }
  }

  .noApptmContainer {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  .switchButton {
    font-family: 'Avenir';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #2F80ED;
    margin-bottom: 25px;
    padding: 0 22px;
  }

  .mobileDatePicker {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: 100%;
    margin-bottom: 20px;

    label {
      margin-right: 0.5rem;
      font-size: 1rem;
      font-weight: 600;
      color: $basePurple;
    }

    .react-datepicker {
      border: 1px solid #BDBDBD !important;
      border-radius: 10px;
    }

    .react-datepicker-wrapper {
      width: 120px;

      input {
        display: flex;
        justify-content: center;
        color: #18181B;
        font-weight: 500;
        font-family: $base-font-family;
        background: #fff;
        border: 1px solid #BDBDBD;
        border-radius: 10px;
        padding: 12px;
        width: 120px;
        font-size: 16px;
        height: 40px;
        position: relative;
        cursor: pointer;
      }
    }
  }

  .incompleteFiltersWrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    margin-top: 32px;
    padding: 0 48px;

    .incompleteFilterButton {
      display: flex;
      align-items: center;
      gap: 6px;

      border-radius: 8px;
      border: 1px solid #E0E0E0;
      padding: 8px 24px;
      box-sizing: border-box;
      cursor: pointer;
      text-transform: capitalize;

      color: #000;
      text-align: center;
      font-family: "Source Sans Pro", sans-serif;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.28px;

      span {
        font-size: 20px;
        font-weight: 600;
      }
    }

    .incompleteActiveFilterButton {
      background-color: rgba(13, 28, 139, 0.05);
    }
  }

  .unsubmittedWrapper {
    margin-top: 26px;
    padding: 0 48px;
    box-sizing: border-box;

    &__banner {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 12px;
      background: #FFEFB6;
      border-radius: 2px;
      padding: 14px 24px;
      box-sizing: border-box;

      font-family: "Source Sans Pro", sans-serif;
      font-size: 14px;
      font-weight: 600;
    }
  }
}