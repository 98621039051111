.clients {
  body.admin-mode & {
    padding-top: 71px;

    @media screen and (max-width: 1024px) {
      padding-top: 0;
    }
  }

  .mobileHeader {
    & > header {
      padding: 1.75rem 1.375rem 0.688rem;

      .justifyRow {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 1rem;

        h2 {
          margin: 0;
          font-size: 40px;
          line-height: normal;
          font-family: "Source Sans Pro", sans-serif;
          font-weight: 600;
          color: $basePurple;

          @media screen and (max-width: 1024px) {
            font-family: 'Avenir Next';
            font-style: normal;
            font-weight: 600;
            font-size: 2rem;
            color: #000000;
            padding: 0;
          }
        }
      }

      .toggle {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        &__text {
          margin-right: 1rem;
          font-size: 1rem;
          font-weight: 600;
          color: $basePurple;
        }
      }
    }
  }

  .hideMobile {
    @media screen and (max-width: 1024px) {
      display: none;
    }

    & > header {
      padding: 3rem;

      .justifyRow {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 1rem;

        h2 {
          margin: 0;
          font-size: 1.5rem;
          line-height: 1.5rem;
          font-family: 'Lora', serif;
          font-weight: 400;
          color: $basePurple;
        }
      }

      .toggle {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        &__text {
          margin-right: 1rem;
          font-size: 1rem;
          font-weight: 600;
          color: $basePurple;
        }
      }
    }
    & > section {
      padding: 3rem 2rem 2rem;
      h3 {
        margin: 0 0 2rem;
        font-size: 1.5rem;
        line-height: 1.5rem;
        font-family: 'Lora', serif;
        font-weight: 400;
        color: $basePurple;
      }

      table {
        width: 100%;
        border-collapse: collapse;
        tr {
          border-bottom: 1px solid #D0D0D3;
        }
        th, td {
          &:nth-child(1) { width: 15%; }
          &:nth-child(2) { width: 15%; }
          &:nth-child(3) { width: 15%; }
          &:nth-child(4) { width: 15%; white-space: pre-line; }
          &:nth-child(5) { width: 15%; white-space: pre-line; }
          &:nth-child(6) { width: 15%; }
          &:nth-child(7) { width: 15%; }
        }
        thead {
          th {
            padding-bottom: 1.25rem;
            font-size: 0.875rem;
            color: #4F4F4F;
            text-align: left;
          }
        }
        tbody {
          tr {
            &:last-child {
              border-bottom: 0;
            }

            &:hover {
              background: #F2F2F2;
            }
          }
          td {
            padding: 1.25rem 0;
            letter-spacing: 0.02em;
            color: $pureBlack;
          }
        }
      }
    }

    .scheduleButton, .archiveButton {
      background: transparent;
      border: none;
      width: fit-content;
      font-weight: bold;
      text-decoration-line: underline;
      color: $basePurple;
      cursor: pointer;
      text-align: left;
    }
  }

  .calendar__eventEditor {
    position: fixed;
    top: 0; right: 0;
    width: 55%;

    @media screen and (max-width: 1024px) {
      width: 100%;
    }
  }

  .mobileContainer {
    display: block;
    height: 100%;

    @media screen and (min-width: 1024px) {
      display: none;
    }
  }

  .sectionContainer {
    height: 100%;

    &--marginTop {
      margin-top: 0.875rem;
    }
  }

  .mobileClientCard {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 23px 20px;
    box-sizing: border-box;
    background: #ffffff;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .mobileClientCardWrapper {
      display: flex;
      align-items: center;
      gap: 0.938rem;
    }

    .clientName {
      font-family: 'Avenir';
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.375rem;
      color: #000000;
    }

    .viewDetails {
      font-family: 'Avenir';
      font-weight: 500;
      font-size: 0.875rem;
      line-height: 1.188rem;
      color: #0A148D;
    }
  }
}
